<template>
    <div>
        <div>
            <el-card shadow="never">
                <div slot="header" class="title">
                    <span>{{title}}</span>
                </div>
                <el-form :inline="true" :model="searchForm" style="margin-left: 20px;" size="mini">
                    <el-row>
                        <el-form-item label="字段键名" prop="fieldEn">
                            <el-input class="input" v-model="searchForm.fieldEn" placeholder="字段键名" clearable/>
                        </el-form-item>
                        <el-form-item label="中文说明" prop="fieldCn">
                            <el-input class="input" v-model="searchForm.fieldCn" placeholder="中文说明" clearable/>
                        </el-form-item>
                        <el-form-item label="寄存器地址" prop="address">
                            <el-input class="input" v-model="searchForm.address" placeholder="寄存器地址" clearable/>
                        </el-form-item>
                        <el-form-item label="寄存器类型" prop="registerType">
                            <el-select class="input" v-model="searchForm.registerType" clearable placeholder="寄存器类型">
                                <el-option v-for="item in registerTypeSelect"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item class="button">
                            <el-button type="primary" @click="queryTableData">查询
                            </el-button>
                        </el-form-item>
                        <el-form-item class="button">
                            <el-button type="primary" @click="openAddOrEdit('add', null)">新增寄存器
                            </el-button>
                        </el-form-item>
                        <el-form-item class="button">
                            <el-button type="primary" @click="openAddCommand">新增指令
                            </el-button>
                        </el-form-item>
                    </el-row>
                </el-form>
                <el-table :data="tableData" border size="small" stripe
                          :header-cell-style="{background:'#eef1f6',color:'#606266', textAlign: 'center'}"
                          v-loading="listLoading">
                    <el-table-column type="index" width="50px" label="序号" align="center"></el-table-column>
                    <el-table-column prop="fieldEn" :show-overflow-tooltip="true" label="字段键名" align="center"/>
                    <el-table-column prop="fieldCn" :show-overflow-tooltip="true" label="中文说明" width="130px" align="left"/>
                    <el-table-column prop="rwModel" label="读写模式" width="50px" align="center" :formatter="rwModelFormat"/>
                    <el-table-column prop="address" label="寄存器地址" width="90px" align="center"/>
                    <el-table-column prop="addressLength" label="长度(字节)" width="56px" align="center"/>
                    <el-table-column prop="registerType" label="类型" width="90px" align="center" :formatter="registerTypeFormat"/>
                    <el-table-column prop="endian" label="大小端" width="60px" align="center" :formatter="endianFormat"/>
                    <el-table-column prop="analysisMode" label="数据类型" width="75px" align="center" :formatter="analysisModeFormat"/>
                    <el-table-column prop="factor" label="倍率" width="80px" align="center"/>
                    <el-table-column prop="unit" label="单位" width="60px" align="center"/>
                    <el-table-column prop="autoAnalysis" label="是否解析" width="50px" align="center" :formatter="yesNoFormat"/>
                    <el-table-column prop="groupName" :show-overflow-tooltip="true" label="组名" align="center"/>
                    <el-table-column prop="groupOrder" label="组中序号" width="50px" align="center"/>
                    <el-table-column prop="triggerEvent" label="触发事件" width="50px" align="center" :formatter="yesNoFormat"/>
                    <el-table-column width="80px" label="操作" fixed="right" align="center">
                        <template slot-scope="scope">
                            <el-row>
                                <el-col :span="12">
                                    <el-tooltip effect="dark" content="编辑" placement="top">
                                        <i class="el-icon-edit"  style="font-size: 18px;color: #409EFF;cursor: pointer;vertical-align: middle" @click="openAddOrEdit('edit', scope.row)"/>
                                    </el-tooltip>
                                </el-col>
                                <el-col :span="12">
                                    <el-tooltip effect="dark" content="删除" placement="top">
                                        <i class="el-icon-delete" style="font-size: 18px;color: #f78989;cursor: pointer;vertical-align: middle" @click="deleteRegisterInfo(scope.row.id)"/>
                                    </el-tooltip>
                                </el-col>
                            </el-row>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-select
                        v-model="searchForm.pageSize"
                        size="mini"
                        @change="handleSizeChange"
                        style="margin: 2px -175px 0 0;width: 100px"
                    >
                        <el-option
                            v-for="item in pageOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <el-pagination
                        background
                        @current-change="handleCurrentChange"
                        :current-page.sync="searchForm.pageNum"
                        :page-size="searchForm.pageSize"
                        layout="total, prev, pager, next, jumper"
                        :total="tableTotal"
                    />
                </div>
            </el-card>

            <el-dialog :title="addOrEditRegisterTitle" :visible.sync="addOrEditVisible" center :close-on-click-modal="false" width="900px" top="5vh">
                <el-steps :active="active" :align-center="true">
                    <el-step @click.native="stepChange(0)" :status="active0Status" title="基础信息"></el-step>
                    <el-step @click.native="stepChange(1)" :status="active1Status" title="枚举信息"></el-step>
                    <el-step @click.native="stepChange(2)" :status="active2Status" title="校验信息"></el-step>
                </el-steps>
                <div style="height: 520px">
                    <div v-show="active === 0" style="padding-top: 20px">
                        <el-form :model="registerForm.baseInfo" :rules="formRules.baseFormRules" ref="baseForm" label-width="110px"
                                 :inline="true" size="mini">
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="字段键名" prop="fieldEn">
                                        <el-input v-model="registerForm.baseInfo.fieldEn" :disabled="registerForm.bindCommand"
                                              placeholder="请输入字段键名" autocomplete="off" class="optItem"/>
                                        <el-popover placement="top" trigger="click" content="用以存储该寄存器解析后值的键名">
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="中文说明" prop="fieldCn">
                                        <el-input v-model="registerForm.baseInfo.fieldCn" placeholder="请输入中文说明" autocomplete="off" class="optItem"/>
                                        <el-popover placement="top" trigger="click" content="该寄存器代表的含义">
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="读写模式" prop="rwModel">
                                        <el-select v-model="registerForm.baseInfo.rwModel" :disabled="registerForm.bindCommand"
                                               clearable placeholder="请选择读写模式" class="optItem">
                                            <el-option
                                                    v-for="item in rwModelSelect"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <el-popover trigger="click" content="该寄存器支持读或读/写" placement="top">
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="类型" prop="registerType">
                                        <el-select v-model="registerForm.baseInfo.registerType" clearable placeholder="请选择寄存器类型"
                                                   @change="registerTypeChange"
                                                   @visible-change="registerTypeVisibleChange" class="optItem">
                                            <el-option
                                                    v-for="item in registerTypeSelect"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <el-popover placement="top" trigger="click" content="按照实际类型选择即可">
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="地址" prop="address">
                                        <el-input v-model="registerForm.baseInfo.address" :disabled="registerForm.bindCommand" placeholder="请输入地址" autocomplete="off" class="optItem"/>
                                        <el-popover trigger="click" content="寄存器的地址，如0000" placement="top">
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="长度(字节)" prop="addressLength">
                                        <el-select v-model="registerForm.baseInfo.addressLength" :disabled="registerForm.bindCommand" clearable placeholder="请选择长度"
                                                   @change="addressLengthChange"
                                                   @visible-change="addressLengthVisibleChange" class="optItem">
                                            <el-option
                                                    v-for="item in addressLengthSelect"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <el-popover trigger="click" content="寄存器的长度，一般为2字节" placement="top">
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="数据类型" prop="analysisMode">
                                        <el-select v-model="registerForm.baseInfo.analysisMode" @change="analysisModeChange" clearable placeholder="请选择数据类型" class="optItem">
                                            <el-option
                                                    v-for="item in analysisModeSelect"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <el-popover trigger="click" content="有符号、无符号、BIT位或ASCII" placement="top">
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="大小端模式" prop="endian">
                                        <el-select v-model="registerForm.baseInfo.endian" clearable placeholder="请选择大小端模式" class="optItem">
                                            <el-option
                                                    v-for="item in endianSelect"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <el-popover trigger="click" content="解析该寄存器值的模式，如果寄存器长度等于1，任选其一，如果大于1，选择实际解析的模式" placement="top">
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="是否解析" prop="autoAnalysis">
                                        <el-select v-model="registerForm.baseInfo.autoAnalysis" clearable placeholder="请选择是否解析" class="optItem">
                                            <el-option
                                                    v-for="item in booleanSelect"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <el-popover trigger="click" content="是则解析该寄存器的值，否则不解析该寄存器的值" placement="top">
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="是否触发事件" prop="triggerEvent">
                                        <el-select v-model="registerForm.baseInfo.triggerEvent" clearable placeholder="请选择是否触发事件" class="optItem">
                                            <el-option
                                                    v-for="item in booleanSelect"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <el-popover trigger="click" content="若为'是',则根据枚举信息列表作为告警事件绑定的的选项" placement="top">
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="组名" prop="groupName">
                                        <el-input v-model="registerForm.baseInfo.groupName" :disabled="registerForm.bindCommand" placeholder="请输入组名" autocomplete="off" class="optItem"/>
                                        <el-popover trigger="click" content="同一个组名下的寄存器会解析成一个字段值，组名作用同字段键名" placement="top">
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="组中序号" prop="groupOrder">
                                        <el-input v-model="registerForm.baseInfo.groupOrder" :disabled="registerForm.bindCommand" placeholder="请输入组中序号" autocomplete="off" class="optItem"/>
                                        <el-popover trigger="click" content="按照组中序号的顺序对组中寄存器进行解析并组合值" placement="top">
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="倍率/系数" prop="factor">
                                        <el-input v-model="registerForm.baseInfo.factor" placeholder="请输入倍率/系数" autocomplete="off" class="optItem"/>
                                        <el-popover trigger="click" content="指令上报时对应数据乘上该倍率，指令下发时对应数据除上该倍率" placement="top">
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="单位" prop="unit">
                                        <el-input v-model="registerForm.baseInfo.unit" placeholder="请输入单位" autocomplete="off" class="optItem"/>
                                        <el-popover trigger="click" content="该寄存器值的单位" placement="top">
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <el-form-item label="备注：" prop="remark" class="myTextArea">
                                        <el-input v-model="registerForm.baseInfo.remark" autocomplete="off" :show-word-limit="true"
                                                  maxlength="250" type="textarea" :rows="4"/>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                    <div v-show="active === 1" style="padding-top: 50px">
                        <el-form :rules="formRules.enumRules" ref="registerEnumForm" label-width="110px" :inline="true" size="mini">
                            <el-row>
                                <el-col :span="24">
                                    <el-form-item prop="modbusRegisterEnumList">
                                        <el-button type="primary" @click="openEnumAdd" size="mini">新增枚举</el-button>
                                        <el-table :data="registerForm.enumInfoList" border size="small" stripe height="440"
                                                  :header-cell-style="{background:'#eef1f6',color:'#606266', textAlign: 'center'}" style="width: 850px">
                                            <el-table-column type="index" width="50px" label="序号" align="center"></el-table-column>
                                            <el-table-column prop="enumEn" label="事件键名" align="center"/>
                                            <el-table-column prop="enumCn" label="中文说明" align="center"/>
                                            <el-table-column prop="bit" label="bit位" align="center"/>
                                            <el-table-column prop="triggerValue" label="触发值" align="center"/>
                                            <el-table-column width="80px" label="操作" fixed="right" align="center">
                                                <template slot-scope="scope">
                                                    <el-row>
                                                        <el-col :span="12">
                                                            <el-tooltip effect="dark" content="编辑" placement="top">
                                                                <i class="el-icon-edit"  style="font-size: 18px;color: #409EFF;cursor: pointer;vertical-align: middle" @click="openEnumEdit(scope.row)"/>
                                                            </el-tooltip>
                                                        </el-col>
                                                        <el-col :span="12">
                                                            <el-tooltip effect="dark" content="删除" placement="top">
                                                                <i class="el-icon-delete" style="font-size: 18px;color: #f78989;cursor: pointer;vertical-align: middle" @click="deleteEnumRow(scope.$index, registerForm.enumInfoList, scope.row)"/>
                                                            </el-tooltip>
                                                        </el-col>
                                                    </el-row>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                    <div v-show="active === 2" style="padding-top: 50px;text-align: center">
                        <el-form :model="registerForm.validateInfo" :rules="formRules.validateFormRules" ref="validateRuleForm"
                                 label-width="120px" :inline="true" size="mini">
                            <el-row>
                                <el-col :span="24">
                                    <el-form-item label="是否开启校验" prop="validateFlag">
                                        <el-select v-model="registerForm.validateInfo.validateFlag" placeholder="请选择" class="optItem">
                                            <el-option
                                                    v-for="item in booleanSelect"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <el-popover placement="top" trigger="click" content="选择是则开启校验,选择否则关闭校验">
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <el-form-item label="是否强制校验" prop="forcedValidate">
                                        <el-select v-model="registerForm.validateInfo.forcedValidate" placeholder="请选择" class="optItem">
                                            <el-option
                                                    v-for="item in booleanSelect"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <el-popover placement="top" trigger="click" content="当开启校验时,选择是,则强制校验该寄存器,选择否,则不会强制校验该寄存器">
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <el-form-item label="提示信息" prop="errorMessage">
                                        <el-input v-model="registerForm.validateInfo.errorMessage" placeholder="请输入提示信息" autocomplete="off" class="optItem"/>
                                        <el-popover placement="top" trigger="click" content="校验失败后展示的提示信息">
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <el-form-item label="校验类型" prop="validateType">
                                        <el-select v-model="registerForm.validateInfo.validateType" placeholder="请选择" class="optItem">
                                            <el-option
                                                    v-for="item in validateTypeSelect"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <el-popover placement="top" trigger="click" content="根据选择的类型对该寄存器的值进行校验">
                                            <i slot="reference" class="el-icon-info field_tip_icon"/>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <div v-show="registerForm.validateInfo.validateType === 1 || registerForm.validateInfo.validateType === 2">
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="最小值" prop="minValue">
                                            <el-input v-model="registerForm.validateInfo.minValue" placeholder="请输入最小值" autocomplete="off" class="optItem"/>
                                            <el-popover placement="top" trigger="click" content="该寄存器允许修改的最小值">
                                                <i slot="reference" class="el-icon-info field_tip_icon"/>
                                            </el-popover>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="最大值" prop="maxValue">
                                            <el-input v-model="registerForm.validateInfo.maxValue" placeholder="请输入最大值" autocomplete="off" class="optItem"/>
                                            <el-popover placement="top" trigger="click" content="该寄存器允许修改的最大值">
                                                <i slot="reference" class="el-icon-info field_tip_icon"/>
                                            </el-popover>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                            <div v-show="registerForm.validateInfo.validateType === 2">
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="小数位数" prop="decimalPlaces">
                                            <el-input v-model="registerForm.validateInfo.decimalPlaces" placeholder="请输入小数位数" autocomplete="off" class="optItem"/>
                                            <el-popover placement="top" trigger="click" content="修改该寄存器的值最多拥有小数的位数">
                                                <i slot="reference" class="el-icon-info field_tip_icon"/>
                                            </el-popover>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                            <div v-show="registerForm.validateInfo.validateType === 3">
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="正则表达式" prop="regularExpression">
                                            <el-input v-model="registerForm.validateInfo.regularExpression" placeholder="请输入正则表达式" autocomplete="off" class="optItem"/>
                                            <el-popover placement="top" trigger="click" content="修改该寄存器的值时,使用该正则表达式进行校验">
                                                <i slot="reference" class="el-icon-info field_tip_icon"/>
                                            </el-popover>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-form>
                    </div>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" size="mini" @click="last" v-if="this.active > 0">上一步</el-button>
                    <el-button type="primary" size="mini" @click="addOrEditRegister" v-if="this.active === 2" :loading="addOrEditLoading">保存</el-button>
                    <el-button type="primary" size="mini" @click="next" v-else>下一步</el-button>
                    <el-button size="mini" @click="addOrEditVisible = false">取消</el-button>
                </div>
            </el-dialog>

            <el-dialog title="寄存器导入" :visible.sync="dataImportVisible" center :close-on-click-modal="false" width="600px">
                <div style="height: 130px;">
                    <el-upload ref="fileUpload"
                               action="/doartIot/doart-modbus/registerInfo/import"
                               accept=".xls,.xlsx"
                               :limit="1"
                               :auto-upload="false"
                               :show-file-list="true"
                               :multiple="false"
                               :file-list="fileList"
                               :headers="headers"
                               list-type="picture"
                               :on-success="fileUploadSuccessEvent"
                               :on-change="fileChangeEvent"
                               :on-error="fileUploadErrorEvent"
                               :data="uploadData"
                    >
                        <el-button size="small" type="primary" style="margin-bottom: 6px;">请选择文件</el-button>
                        <div slot="tip">只能上传 <span style="color: red;">.xls</span> 和 <span style="color: red;">.xlsx</span> 格式文件</div>
                    </el-upload>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" type="primary" @click="downloadTemplate">下载模板</el-button>
                    <el-button size="small" type="primary" @click="importRegister" :loading="importLoading">导入数据</el-button>
                    <el-button size="small" @click="closeImport">取消</el-button>
                </div>
            </el-dialog>
            <el-dialog title="导入异常提示信息" :visible.sync="errorInfoVisible" center :close-on-click-modal="false" width="800px">
                <div style="height: 400px; overflow: auto">
                    <div style="color: red;margin-bottom: 4px;">错误数据，请检查！</div>
                    <el-table :data="errorData" border size="small" stripe style="width: 100%;"
                              :header-cell-style="{background:'#eef1f6',color:'#606266'}" v-loading="errorInfoLoading">
                        <el-table-column prop="fieldEn" label="字段键名" width="150px" show-overflow-tooltip align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.fieldEn.validate">
                                    {{scope.row.fieldEn.value}}
                                </span>
                                <span v-else style="color: red">
                                    {{scope.row.fieldEn.value}}（{{scope.row.fieldEn.tip}}）
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="fieldCn" label="中文说明" width="150px" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.fieldCn.validate">
                                    {{scope.row.fieldCn.value}}
                                </span>
                                <span v-else style="color: red">
                                    {{scope.row.fieldCn.value}}（{{scope.row.fieldCn.tip}}）
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="rwModel" label="读写模式" width="80px" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.rwModel.validate">
                                    {{scope.row.rwModel.value}}
                                </span>
                                <span v-else style="color: red">
                                    {{scope.row.rwModel.value}}（{{scope.row.rwModel.tip}}）
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="address" label="寄存器地址" width="120px" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.address.validate">
                                    {{scope.row.address.value}}
                                </span>
                                <span v-else style="color: red">
                                    {{scope.row.address.value}}（{{scope.row.address.tip}}）
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="addressLength" label="长度(字节)" width="100px" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.addressLength.validate">
                                    {{scope.row.addressLength.value}}
                                </span>
                                <span v-else style="color: red">
                                    {{scope.row.addressLength.value}}（{{scope.row.addressLength.tip}}）
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="registerType" label="类型" width="80px" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.registerType.validate">
                                    {{scope.row.registerType.value}}
                                </span>
                                <span v-else style="color: red">
                                    {{scope.row.registerType.value}}（{{scope.row.registerType.tip}}）
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="endian" label="大小端模式" width="100px" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.endian.validate">
                                    {{scope.row.endian.value}}
                                </span>
                                <span v-else style="color: red">
                                    {{scope.row.endian.value}}（{{scope.row.endian.tip}}）
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="analysisMode" label="数据类型" width="100px" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.analysisMode.validate">
                                    {{scope.row.analysisMode.value}}
                                </span>
                                <span v-else style="color: red">
                                    {{scope.row.analysisMode.value}}（{{scope.row.analysisMode.tip}}）
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="factor" label="倍率/系数" width="100px" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.factor.validate">
                                    {{scope.row.factor.value}}
                                </span>
                                <span v-else style="color: red">
                                    {{scope.row.factor.value}}（{{scope.row.factor.tip}}）
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="unit" label="单位" width="100px" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.unit.validate">
                                    {{scope.row.unit.value}}
                                </span>
                                <span v-else style="color: red">
                                    {{scope.row.unit.value}}（{{scope.row.unit.tip}}）
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="autoAnalysis" label="是否解析" width="100px" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.autoAnalysis.validate">
                                    {{scope.row.autoAnalysis.value}}
                                </span>
                                <span v-else style="color: red">
                                    {{scope.row.autoAnalysis.value}}（{{scope.row.autoAnalysis.tip}}）
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="groupName" label="组名" width="150px" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.groupName.validate">
                                    {{scope.row.groupName.value}}
                                </span>
                                <span v-else style="color: red">
                                    {{scope.row.groupName.value}}（{{scope.row.groupName.tip}}）
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="groupOrder" label="组中序号" width="100px" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.groupOrder.validate">
                                    {{scope.row.groupOrder.value}}
                                </span>
                                <span v-else style="color: red">
                                    {{scope.row.groupOrder.value}}（{{scope.row.groupOrder.tip}}）
                                </span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="errorInfoVisible=false">关闭</el-button>
                </div>
            </el-dialog>
            <el-dialog :title="enumTitle" :visible.sync="registerEnumVisible" width="350px" center :close-on-click-modal="false">
                <el-form label-width="80px" :model="enumForm" :rules="formRules.enumRules" ref="enumForm" :inline="true" size="mini">
                    <el-row>
                        <el-form-item label="事件键名" prop="enumEn">
                            <el-input v-model="enumForm.enumEn" placeholder="请输入事件键名" autocomplete="off"/>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="中文说明" prop="enumCn">
                            <el-input v-model="enumForm.enumCn" placeholder="请输入中文说明" autocomplete="off"/>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="bit位" prop="bit">
                            <el-input v-model="enumForm.bit" placeholder="请输入bit位" autocomplete="off"/>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item label="触发值" prop="triggerValue">
                            <el-input v-model="enumForm.triggerValue" placeholder="请输入触发值" autocomplete="off"/>
                        </el-form-item>
                    </el-row>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="enumSubmit">确认</el-button>
                    <el-button @click="registerEnumVisible = false">取消</el-button>
                </div>
            </el-dialog>
            <ModbusCommandOperation ref="modbusCommandOperationPage" @refreshSettingTree="refreshSettingTree"></ModbusCommandOperation>
        </div>
    </div>
</template>

<script>
    import NavBreadcrumb from '@/components/NavBreadcrumb.vue';
    import {
        addRegisterInfo,
        deleteRegisterInfo,
        editRegisterInfo,
        queryList,
        queryPageList,
        queryRegisterInfoDetail
    } from '@/api/modbus/modbusRegister';
    import ModbusCommandOperation from '@/views/modbus/modbus-command-operation';
    import message from '@/utils/message';
    import {getToken} from '@/api/auth';

    export default {

    name: 'modbus-register',
    components: {NavBreadcrumb, ModbusCommandOperation},
    props: {

        pointInfoId: {

            type: Number,
            default: 0,
        },
        title: {

            type: String,
            default: '',
        }
    },
    watch:{

        pointInfoId(){

            this.queryTableData();
        }
    },
    data() {

        let fieldEnValidator = (rule, value, callback) => {

            if (value === '') {

                callback(new Error('请输入字段键名'));
            }
            if (value === this.registerForm.baseInfo.groupName) {

                callback(new Error('不能与组名重复'));
            }
            callback();
        };

        let groupNameValidator = (rule, value, callback) => {

            if (value === '') {

                callback(new Error('请输入组名'));
            }
            if (value === this.registerForm.baseInfo.fieldEn) {

                callback(new Error('不能与字段键名重复'));
            }
            callback();
        };

        let maxAndMinValueValidator = (rule, value, callback) => {

            if (this.registerForm.validateInfo.validateFlag === 1) {

                if (value === '') {

                    callback(new Error('不可为空'));
                } else if (this.registerForm.validateInfo.validateType === 1) {

                    // 整数
                    if (!/^-?\d+$/.test(value)) {

                        callback(new Error('只能为整数'));
                    }
                } else if (this.registerForm.validateInfo.validateType === 2) {

                    // 小数
                    if (!/^-?\d+\.\d+$/.test(value)) {

                        callback(new Error('只能为小数,必须包含小数位'));
                    }
                }
            }
            callback();
        };

        let decimalPlacesValidator = (rule, value, callback) => {

            if (this.registerForm.validateInfo.validateFlag === 1) {

                if (value === '') {

                    callback(new Error('请输入小数位数'));
                } else if (!/^[0-9]*$/.test(value)) {

                    callback(new Error('只能输入0-9数字'));
                }
            }
            callback();
        };

        let regularExpressionValidator = (rule, value, callback) => {

            if (this.registerForm.validateInfo.validateFlag === 1 && this.registerForm.validateInfo.validateType === 3) {

                if (value === '') {

                    callback(new Error('请输入正则表达式'));
                } else if (value.length > 60) {

                    callback(new Error('最大长度60字符'));
                }
            }
            callback();
        };

        return {

            searchForm: {

                pageNum: 1,
                pageSize: 10,
                fieldEn: '',
                fieldCn: '',
                address: '',
                registerType: ''
            },
            registerOpt: 'add',
            addOrEditRegisterTitle: '',
            listLoading: false,
            tableData: [],
            tableTotal: 0,

            headers: {

                'Authorization' : 'Bearer ' + getToken()
            },
            uploadData: {pointInfoId: this.pointInfoId},
            dataImportVisible: false,
            importLoading: false,
            errorData: [],
            errorInfoVisible: false,
            errorInfoLoading: false,
            fileList: [],

            addOrEditLoading: false,
            addOrEditVisible: false,
            registerForm: {

                id: -1,
                pointInfoId: '',
                bindCommand: false,
                baseInfo: {

                    fieldEn: '',
                    fieldCn: '',
                    rwModel: '',
                    address: '',
                    addressLength: '',
                    registerType: '',
                    endian: '',
                    analysisMode: '',
                    factor: '',
                    unit: '',
                    autoAnalysis: '',
                    groupName: '',
                    groupOrder: '',
                    triggerEvent: '',
                    remark: ''
                },
                enumInfoList: [],
                enumDeleteIdList: [],
                validateInfo: {

                    id: '',
                    validateFlag: '',
                    forcedValidate: '',
                    errorMessage: '',
                    validateType: '',
                    maxValue: 0,
                    minValue: 0,
                    decimalPlaces: 0,
                    regularExpression: ''
                }
            },

            registerEnumVisible: false,
            enumTitle: '',
            nowRow: '',
            enumForm: {

                enumEn: '',
                enumCn: '',
                bit: '',
                triggerValue: '',
            },

            validateFormValidateFlag: true,
            formRules: {

                baseFormRules: {

                    fieldEn: [
                        { required: true, validator: fieldEnValidator, trigger: ['blur', 'change'] },
                        { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: ['blur', 'change'] },
                        { pattern: /^[a-zA-Z0-9](?:_?[a-zA-Z0-9]+)*$/, message: '字母、数字、下划线，不能以下划线开始或结束', trigger: ['blur', 'change'] },
                    ],
                    fieldCn: [
                        { required: true, message: '请输入中文说明', trigger: ['blur', 'change'] },
                        { min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: ['blur', 'change'] }
                    ],
                    rwModel: [
                        { required: true, message: '请选择读写模式', trigger: ['blur', 'change']},
                    ],
                    registerType: [
                        {required: true, message: '请选择类型', trigger: ['blur', 'change']},
                    ],
                    address: [
                        { required: true, message: '请输入地址', trigger: ['blur', 'change'] },
                        { min: 1, max: 16, message: '长度在 1 到 16 个字符', trigger: ['blur', 'change'] },
                        { pattern: /^[0-9a-fA-F]*$/, message: '可输入0-9数字和字母A-F', trigger: ['blur', 'change'] }
                    ],
                    addressLength: [
                        { required: true, message: '请选择长度', trigger: ['blur', 'change'] },
                    ],
                    analysisMode: [
                        { required: true, message: '请选择数据类型', trigger: ['blur', 'change'] },
                    ],
                    endian: [
                        { required: true, message: '请选择大小端模式', trigger: ['blur', 'change'] },
                    ],
                    autoAnalysis: [
                        { required: true, message: '请选择是否解析', trigger: ['blur', 'change'] },
                    ],
                    triggerEvent: [
                        { required: true, message: '请选择是否触发事件', trigger: ['blur', 'change'] },
                    ],
                    groupName: [
                        { required: true, validator: groupNameValidator, trigger: ['blur', 'change'] },
                        { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: ['blur', 'change'] },
                        { pattern: /^[a-zA-Z0-9](?:_?[a-zA-Z0-9]+)*$/, message: '字母、数字、下划线，不能以下划线开始或结束', trigger: ['blur', 'change'] },
                    ],
                    groupOrder: [
                        { required: true, message: '请输入组中序号', trigger: ['blur', 'change'] },
                        { pattern: /^[0-9]*[1-9][0-9]*$/, message: '只能输入正整数', trigger: ['blur', 'change'] },
                    ],
                    factor: [
                        { required: true, message: '请输入倍率/系数', trigger: ['blur', 'change'] },
                        { pattern: /^(\+|-)?\d+(\.\d+)?$/g, message: '只能输入整数和小数', trigger: ['blur', 'change'] }
                    ],
                    unit: [
                        { min: 0, max: 10, message: '最大长度10字符', trigger: ['blur', 'change'] },
                        { pattern: /^\S*$/, message: '注意空格', trigger: ['blur', 'change'] }
                    ],
                    remark: [
                        { min: 0, max: 250, message: '最大长度250字符', trigger: ['blur', 'change'] },
                    ],
                },
                enumRules: {

                    enumEn: [
                        { required: true, message: '请输入事件键名', trigger: ['blur', 'change'] },
                        { min: 1, max: 64, message: '长度在 1 到 64 个字符', trigger: ['blur', 'change'] }
                    ],
                    enumCn: [
                        { required: true, message: '请输入中文说明', trigger: ['blur', 'change'] },
                        { min: 1, max: 64, message: '长度在 1 到 64 个字符', trigger: ['blur', 'change'] }
                    ],
                    bit: [
                        { pattern: /^[1-9]\d*|0$/, message: '请输入正整数', trigger: ['blur', 'change'] },
                        { min: 1, max: 2, message: '最多2位', trigger: ['blur', 'change'] }
                    ],
                    triggerValue: [
                        { required: true, message: '请输入触发值', trigger: ['blur', 'change'] },
                        { min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: ['blur', 'change'] }
                    ]
                },
                validateFormRules: {

                    validateFlag: [
                        { required: true, message: '请选择是否开启校验', trigger: ['change'] }
                    ],
                    forcedValidate: [
                        { required: true, message: '请选择是否强制校验', trigger: ['change'] }
                    ],
                    validateType: [
                        { required: true, message: '请选择校验类型', trigger: ['change'] }
                    ],
                    maxValue: [
                        { required: true, validator: maxAndMinValueValidator, trigger: ['blur', 'change'] }
                    ],
                    minValue: [
                        { required: true, validator: maxAndMinValueValidator, trigger: ['blur', 'change'] }
                    ],
                    decimalPlaces: [
                        { required: true, validator: decimalPlacesValidator, trigger: ['blur', 'change'] }
                    ],
                    regularExpression: [
                        { required: true, validator: regularExpressionValidator, trigger: ['blur', 'change'] }
                    ]
                }
            },

            productLineSelect: [],
            pageOptions: [
                {
                    value: 10,
                    label: '10条/页'
                },
                {
                    value: 20,
                    label: '20条/页'
                },
                {
                    value: 50,
                    label: '50条/页'
                },
                {
                    value: 100,
                    label: '100条/页'
                },
                {
                    value: 10000,
                    label: '全部'
                },
            ],
            registerTypeSelect: [
                {
                    value: 1,
                    label: '保持寄存器'
                },
                {
                    value: 2,
                    label: '输入寄存器'
                },
                {
                    value: 3,
                    label: '线圈'
                },
                {
                    value: 4,
                    label: '离散量输入'
                }
            ],
            rwModelSelect: [
                {
                    value: 1,
                    label: '读'
                },
                {
                    value: 2,
                    label: '读/写'
                },
                {
                    value: 3,
                    label: '写'
                }
            ],
            addressLengthSelect: [
                {
                    value: 1,
                    label: '1'
                },
                {
                    value: 2,
                    label: '2'
                }
            ],
            endianSelect: [
                {
                    value: 'LITTLE_ENDIAN',
                    label: '小端'
                },
                {
                    value: 'BIG_ENDIAN',
                    label: '大端'
                }
            ],
            analysisModeSelect: [
                {
                    value: 1,
                    label: '有符号'
                },
                {
                    value: 2,
                    label: '无符号'
                },
                {
                    value: 3,
                    label: 'BIT位'
                },
                {
                    value: 4,
                    label: 'ASCII'
                }
            ],
            booleanSelect: [
                {
                    value: 1,
                    label: '是'
                },
                {
                    value: 0,
                    label: '否'
                }
            ],
            validateTypeSelect: [
                {
                    value: 1,
                    label: '整数'
                },
                {
                    value: 2,
                    label: '小数'
                },
                {
                    value: 3,
                    label: '字符串'
                }
            ],
            // active步骤对应状态
            active0Status: 'process',
            active1Status: 'wait',
            active2Status: 'wait',
            active: 0
        };
    },
    methods: {

        openAddOrEdit(registerOpt, e) {

            this.addOrEditVisible = true;
            this.registerOpt = registerOpt;
            this.active = 0;
            this.active0Status = 'process';
            this.active1Status = 'wait';
            this.active2Status = 'wait';
            this.registerForm.pointInfoId = this.pointInfoId;
            this.registerForm.bindCommand = false;
            this.registerForm.baseInfo.registerType = 1;
            this.registerForm.baseInfo.rwModel = 1;
            this.registerForm.baseInfo.addressLength = 2;
            this.registerForm.baseInfo.endian = 'BIG_ENDIAN';
            this.registerForm.baseInfo.analysisMode = 1;
            this.registerForm.baseInfo.autoAnalysis = 1;
            this.registerForm.baseInfo.fieldEn = '';
            this.registerForm.baseInfo.fieldCn = '';
            this.registerForm.baseInfo.address = '';
            this.registerForm.baseInfo.factor = '';
            this.registerForm.baseInfo.unit = '';
            this.registerForm.baseInfo.groupName = '';
            this.registerForm.baseInfo.groupOrder = '';
            this.registerForm.baseInfo.triggerEvent = 0;
            this.registerForm.baseInfo.remark = '';
            this.registerForm.enumInfoList = [];
            this.registerForm.enumDeleteIdList = [];
            this.registerForm.validateInfo.validateFlag = 1;
            this.registerForm.validateInfo.forcedValidate = 1
            this.registerForm.validateInfo.validateType = 1;
            this.registerForm.validateInfo.maxValue = 0;
            this.registerForm.validateInfo.minValue = 0;
            this.registerForm.validateInfo.decimalPlaces = 0;
            this.registerForm.validateInfo.regularExpression = '';
            this.$nextTick(() => {

                this.$refs['baseForm'].clearValidate();
                this.$refs['registerEnumForm'].clearValidate();
                this.$refs['validateRuleForm'].clearValidate();
            });
            if (registerOpt === 'add') {

                this.addOrEditRegisterTitle = '添加寄存器';
            } else if (registerOpt === 'edit') {

                this.addOrEditRegisterTitle = '编辑寄存器';
                this.registerForm.id = e.id;
                queryRegisterInfoDetail({id: e.id}).then((res) => {

                    if (res.code === 200) {

                        this.registerForm.baseInfo = res.data.baseInfoVo;
                        this.registerForm.enumInfoList = res.data.enumInfoVoList;
                        this.registerForm.validateInfo = res.data.validateInfoVo;
                        this.registerForm.bindCommand = res.data.bindCommand;
                    }
                }).catch(err => { console.error(err) });
                this.analysisModeChange(e.analysisMode);
            }
        },
        addSubmit() {

            let flag = false;
            this.$refs['baseForm'].validate((valid) => {

                if (valid) {

                    flag = valid;
                } else {

                    return false;
                }
            });
            this.$refs['validateRuleForm'].validate((valid) => {

                if (valid) {

                    flag = flag && valid;
                } else {

                    return false;
                }
            });
            if (!flag) {

                return;
            }
            this.addOrEditLoading = true;
            addRegisterInfo(this.registerForm).then((res) => {

                this.addOrEditLoading = false;
                if (res.code === 200) {

                    this.addOrEditVisible = false;
                    this.getTableData();
                    message.success('添加寄存器成功');
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                console.error(err);
            });
        },
        editSubmit() {

            let flag = false;
            this.$refs['baseForm'].validate((valid) => {

                if (valid) {

                    flag = valid;
                } else {

                    return false;
                }
            });
            this.$refs['validateRuleForm'].validate((valid) => {

                if (valid) {

                    flag = flag && valid;
                } else {

                    return false;
                }
            });
            if (!flag) {

                return;
            }
            this.addOrEditLoading = true;
            editRegisterInfo(this.registerForm).then((res) => {

                this.addOrEditLoading = false;
                if (res.code === 200) {

                    this.addOrEditVisible = false;
                    this.getTableData();
                    message.success('编辑寄存器成功');
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                console.error(err);
            });
        },
        deleteRegisterInfo(id) {

            message.confirm('是否要删除该条寄存器信息？', false, 'warning', ()=>{

                deleteRegisterInfo({id: id}).then((res) => {

                    if (res.code === 200) {

                        this.searchForm.pageSize = 10;
                        this.getTableData();
                        message.success('删除成功');
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err=> console.log(err));
            })
        },
        queryTableData() {

            this.searchForm.pageNum = 1;
            if (this.searchForm.pageSize === 10000) {

                this.getTableDataAll();
            } else {

                this.getTableData();
            }
        },
        refreshSettingTree() {

            this.$emit('refreshTree');
        },
        initPage() {

            this.searchForm.pageNum = 1;
            this.getTableData();
        },
        getTableData() {

            this.listLoading = true;
            this.searchForm.pointInfoId = this.pointInfoId;
            queryPageList(this.searchForm).then((res) => {

                this.listLoading = false;
                if (res.code === 200) {

                    this.tableTotal = res.data.total;
                    this.tableData = res.data.result;
                }
            }).catch(err => {

                    console.error(err)
                    this.listLoading = false;
                }
            );
        },
        getTableDataAll() {

            this.listLoading = true;
            this.searchForm.pointInfoId = this.pointInfoId;
            queryList(this.searchForm).then((res) => {

                this.listLoading = false;
                if (res.code === 200) {

                    this.tableData = res.data;
                    if (res.data) {

                        this.tableTotal = res.data.length
                    } else {

                        this.tableTotal = 0;
                    }
                }
            }).catch(err => {

                console.error(err);
                this.listLoading = false;
            });
        },
        handleCurrentChange(val) {

            this.searchForm.pageNum = val;
            this.getTableData();
        },
        handleSizeChange(val) {

            this.searchForm.pageSize = val;
            this.searchForm.pageNum = 1;
            if (val === 10000) {

                this.getTableDataAll();
            } else {

                this.getTableData();
            }
        },
        enumSubmit() {

            this.$refs['enumForm'].validate((valid) => {

                if (valid) {

                    if (this.addOrEditVisible) {

                        if (this.enumTitle === '新增寄存器枚举值') {

                            let netEnumForm = JSON.parse(JSON.stringify(this.enumForm));
                            this.registerForm.enumInfoList.push(netEnumForm);
                        } else {

                            this.nowRow.enumCn = this.enumForm.enumCn;
                            this.nowRow.enumEn = this.enumForm.enumEn;
                            this.nowRow.bit = this.enumForm.bit;
                            this.nowRow.triggerValue = this.enumForm.triggerValue;
                        }
                        this.registerEnumVisible = false;
                    } else {

                        if (this.enumTitle === '新增寄存器枚举值') {

                            let netEnumForm = JSON.parse(JSON.stringify(this.enumForm));
                            this.registerForm.enumInfoList.push(netEnumForm);
                        } else {

                            this.nowRow.enumCn = this.enumForm.enumCn;
                            this.nowRow.enumEn = this.enumForm.enumEn;
                            this.nowRow.bit = this.enumForm.bit;
                            this.nowRow.triggerValue = this.enumForm.triggerValue;
                        }
                        this.registerEnumVisible = false;
                    }
                }
            });
        },
        analysisModeChange(val) {

            if (val === 3) {

                this.formRules.enumRules.bit = [
                    {required: true, message: '请输入bit', trigger: ['blur', 'change']},
                    { pattern: /^[1-9]\d*|0$/, message: '请输入正整数', trigger: ['blur', 'change'] },
                    { min: 1, max: 2, message: '最多2位', trigger: ['blur', 'change'] }
                ]
            } else {

                this.formRules.enumRules.bit = [
                    { pattern: /^[1-9]\d*|0$/, message: '请输入正整数', trigger: ['blur', 'change'] },
                    { min: 1, max: 2, message: '最多2位', trigger: ['blur', 'change'] }
                ]
            }
        },
        registerTypeChange(val) {

            if (val === 3) {

                this.registerForm.baseInfo.addressLength = 1;
                this.registerForm.baseInfo.endian = 'LITTLE_ENDIAN';
            } else {

                this.registerForm.baseInfo.addressLength = 2;
            }
        },
        registerTypeVisibleChange(val) {

            if (!val) {

                if (this.registerForm.baseInfo.registerType === 3) {

                    this.registerForm.baseInfo.addressLength = 1;
                    this.registerForm.baseInfo.endian = 'LITTLE_ENDIAN';
                } else {

                    this.registerForm.baseInfo.addressLength = 2;
                }
            }
        },
        addressLengthChange(val) {

            if (val === 1) {

                this.registerForm.baseInfo.endian = 'LITTLE_ENDIAN';
            }
        },
        addressLengthVisibleChange(val) {

            if (!val && this.registerForm.baseInfo.addressLength === 1) {

                this.registerForm.baseInfo.endian = 'LITTLE_ENDIAN';
            }
        },
        importRegister() {

            this.$refs.fileUpload.submit();
        },
        fileUploadSuccessEvent(response, file, fileList) {

            this.$refs.fileUpload.clearFiles();
            this.queryTableData();
            if (response.code === 200) {

                this.errorData = response.data;
                if (this.errorData && this.errorData.length > 0) {

                    this.errorInfoVisible = true;
                    this.$message({

                        showClose: true,
                        message: '存在有问题的数据，请检查！',
                        type: 'error'
                    });
                } else {

                    this.$message({

                        showClose: true,
                        message: '数据导入成功！',
                        type: 'success'
                    });
                    this.dataImportVisible = false;
                    this.$refs.fileUpload.clearFiles();
                }
            } else {

                this.$message({

                    showClose: true,
                    message: '数据导入失败，请重试！',
                    type: 'error'
                });
            }
        },
        fileUploadErrorEvent() {

            this.$message({

                showClose: true,
                message: '文件上传失败，请重试！',
                type: 'error'
            });
        },
        fileChangeEvent(file) {

            file.url = require('../../assets/image/excel.png');
        },
        downloadTemplate() {

            window.location.href = '/manager/template/ModbusRegisterTemplate.xlsx';
        },
        openImport() {

            this.dataImportVisible = true;
        },
        closeImport() {

            this.dataImportVisible = false;
            this.$refs.fileUpload.clearFiles();
        },
        openAddCommand() {

            this.$refs.modbusCommandOperationPage.addOpenInitPage(this.pointInfoId);
        },
        openEnumAdd() {

            this.enumTitle = '新增寄存器枚举值';
            this.enumForm = {

                enumEn: '',
                enumCn: '',
                bit: '',
                triggerValue: '',
            };
            this.registerEnumVisible = true;
        },
        deleteEnumRow(index, rows, row) {

            rows.splice(index, 1);
            if (row.id) {

                this.registerForm.enumDeleteIdList.push(row.id);
            }
        },
        openEnumEdit(row) {

            this.enumTitle = '编辑寄存器枚举值';
            this.enumForm.enumCn = row.enumCn;
            this.enumForm.enumEn = row.enumEn;
            this.enumForm.bit = row.bit === null ? '' : String(row.bit);
            this.enumForm.triggerValue = row.triggerValue;
            this.nowRow = row;
            this.registerEnumVisible = true;
        },
        rwModelFormat(row, column, cellValue) {

            if (cellValue === 1) {

                return '读';
            } else if (cellValue === 2) {

                return '读/写';
            } else if (cellValue === 3) {

                return '写';
            } else {

                return '未知';
            }
        },
        registerTypeFormat(row, column, cellValue) {

            if (cellValue === 1) {

                return '保持寄存器';
            } else if (cellValue === 2) {

                return '输入寄存器';
            } else if (cellValue === 3) {

                return '线圈';
            } else if (cellValue === 4) {

                return '离线输入';
            } else {

                return '未知';
            }
        },
        endianFormat(row, column, cellValue) {

            if (cellValue === 'BIG_ENDIAN') {

                return '大端';
            } else if (cellValue === 'LITTLE_ENDIAN') {

                return '小端';
            }else {

                return '未知';
            }
        },
        analysisModeFormat(row, column, cellValue) {

            if (cellValue === 1) {

                return '有符号';
            } else if (cellValue === 2) {

                return '无符号';
            } else if (cellValue === 3) {

                return 'BIT位';
            } else if (cellValue === 4) {

                return 'ASCII';
            } else {

                return '未知';
            }
        },
        yesNoFormat(row, column, cellValue) {

            if (cellValue === 1) {

                return '是';
            } else if (cellValue === 0) {

                return '否';
            } else {

                return '未知';
            }
        },
        last() {

            this.active = this.active - 1;
            this.stepChange(this.active)
        },
        next() {

            if (this.active === 0) {

                this.$refs['baseForm'].validate((valid) => {

                    if (valid) {

                        this.active = this.active + 1;
                        this.active0Status = 'success';
                        this.active1Status = 'process';
                    }
                })
            } else if (this.active === 1) {

                this.active = this.active + 1;
                this.active1Status = 'success';
                this.active2Status = 'process';
            }
        },
        stepChange(value) {

            this.refreshStatus(value)
            switch(value){

                case 0:
                    this.active0Status = 'process';
                    this.active1Status = 'wait';
                    this.active2Status = 'wait';
                    break;
                case 1:
                    this.active1Status = 'process';
                    this.active2Status = 'wait';
                    break;
                case 2:
                    this.active2Status = 'process';
                    break;
                default:
                    break;
            }
            this.active = value
        },
        refreshStatus(targetActive) {

            if (targetActive === 0) {

                return;
            }

            // 校验基础信息页面
            this.$refs['baseForm'].validate((valid) => {

                if (valid) {

                    this.active0Status = 'success';
                } else {

                    this.active0Status = 'error';
                }
            });

            if (targetActive === 1) {

                return;
            }
            this.active1Status = 'success';
        },
        addOrEditRegister() {

            let flag = true;
            // 校验基础信息
            this.$refs['baseForm'].validate((valid) => {

                flag = valid;
            })
            if (!flag) {

                this.active0Status = 'process';
                this.active1Status = 'wait';
                this.active2Status = 'wait';
                this.active = 0;
                return
            }
            // 开启了校验规则 则进行校验
            this.$refs['validateRuleForm'].validate((valid, obj) => {

                flag = valid;
            });
            if (flag && this.active === 2) {

                if (this.registerOpt === 'add') {

                    this.addSubmit();
                } else if (this.registerOpt === 'edit') {

                    this.editSubmit();
                } else {

                    console.error('未知的操作类型');
                }
            }
        }
    },
    mounted() {

        this.initPage();
    },
}
</script>

<style scoped>

/deep/.el-pagination__total {

    font-weight: 400;
    color: #606266;
    margin-right: 140px;
}

.title {

    font-weight: bolder;
    font-size: larger;
}

.input {

    width: 190px;
}

.pagination {

    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
}

.own-footer {

    margin-top: -30px;
}

.colTextArea {

    margin-top: -60px;
}

.optItem {

    width: 250px;
}

.button {

    margin: 0 10px 20px 0;
}

/deep/.el-card__body {

    padding: 10px;
}
.field_tip_icon {

    font-size: 20px;
    vertical-align: middle;
    margin-left: 5px;
}
.tableAuto >>>.cell {

    white-space: nowrap;
}
.myTextArea {

    width: 100%;
}
.myTextArea >>> .el-form-item__content {

    width: 80%;
}
</style>
